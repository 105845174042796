exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gatsby-browser-js": () => import("./../../../src/pages/gatsby-browser.js" /* webpackChunkName: "component---src-pages-gatsby-browser-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-funding-initiatives-contact-js": () => import("./../../../src/pages/investors/funding_initiatives_contact.js" /* webpackChunkName: "component---src-pages-investors-funding-initiatives-contact-js" */),
  "component---src-pages-investors-funding-initiatives-js": () => import("./../../../src/pages/investors/funding_initiatives.js" /* webpackChunkName: "component---src-pages-investors-funding-initiatives-js" */),
  "component---src-pages-investors-investment-opportunities-js": () => import("./../../../src/pages/investors/investment_opportunities.js" /* webpackChunkName: "component---src-pages-investors-investment-opportunities-js" */),
  "component---src-pages-investors-success-js": () => import("./../../../src/pages/investors/success.js" /* webpackChunkName: "component---src-pages-investors-success-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-portfolio-1-js": () => import("./../../../src/pages/portfolio/portfolio1.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-1-js" */),
  "component---src-pages-portfolio-portfolio-2-js": () => import("./../../../src/pages/portfolio/portfolio2.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-2-js" */),
  "component---src-pages-portfolio-portfolio-3-js": () => import("./../../../src/pages/portfolio/portfolio3.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-3-js" */),
  "component---src-pages-portfolio-portfolio-4-js": () => import("./../../../src/pages/portfolio/portfolio4.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-4-js" */),
  "component---src-pages-portfolio-portfolio-5-js": () => import("./../../../src/pages/portfolio/portfolio5.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-5-js" */)
}

